<div class="not-found__container">
    <div class="not-found__title">
        {{ errorCode }}
    </div>

    @if (errorCode === '404') {
        <eb-h2 class="not-found__subtitle">{{ 'common.page_not_found' | transloco }}</eb-h2>
    } @else if (errorCode === '403') {
        <eb-h2 class="not-found__subtitle">{{ 'common.unauthorised' | transloco }}</eb-h2>
    }

    <div class="not-found__buttons">
        <button
            eb-button
            (click)="goBack()"
            color="secondary"
            >{{ 'common.back' | transloco }}</button
        >
        <button
            eb-button
            (click)="goShareList()"
            color="secondary"
            >{{ 'common.back_to_shares_list' | transloco }}</button
        >
    </div>
</div>
